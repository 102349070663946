import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';

import { LoggingApiService } from './logging-api.service';
import * as _ from 'lodash';
import { LogLevel } from '@xpo-ltl/sdk-common';
import { LoggingConstants } from './logging-constants';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { tap, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class LoggingHttpInterceptor implements HttpInterceptor {
  private static loggingApiEndpointKey = 'loggingApiEndpoint';
  private static doNotLogRequestPaths: string[] = ['/token', '$(/dmstoken)$'];

  private logStatsPaths: string[] = undefined;
  private logRequestPaths: string[] = undefined;
  private logResponsePaths: string[] = undefined;
  private logExclusionPaths: string[] = undefined;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private loggingApiService: LoggingApiService,
    private injector: Injector,
    private configManager: ConfigManagerService
  ) {
    this.configManager.configured$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(config => {
        if (config) {
          this.logStatsPaths = config.logStatsForPaths;
          this.logRequestPaths = config.logRequestBodyForPaths;
          this.logResponsePaths = config.logResponseBodyForPaths;
          this.logExclusionPaths = config.logExclusionForPaths;
          this.unsubscribe$.next();
          this.unsubscribe$.complete();
          this.unsubscribe$ = null;
        }
      });
  }
  private ValidURL(url) {
    return /^(http|https):\/\/[^ "]+$/.test(url);
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.ValidURL(req.url)) {
      return next.handle(req);
    }

    const url = new URL(req.url);
    const start = new Date();
    if (
      req.url.indexOf(
        this.configManager.getSetting(
          LoggingHttpInterceptor.loggingApiEndpointKey
        )
      ) >= 0
    ) {
      return next.handle(req);
    }
    if (this.shouldLogRequest(url.pathname)) {
      this.logJsonRequest(req.body, 'request', req, url);
    }

    return next.handle(req).pipe(
      distinctUntilChanged(),
      tap(
        response => {
          if (response instanceof HttpResponse) {
            if (this.shouldLogResponse(url.pathname)) {
              this.logJsonResponse(
                response,
                'response',
                req,
                response['status'] as number,
                start,
                url,
                LogLevel.DEBUG
              );
            } else if (this.shouldLogStats(url.pathname)) {
              this.logStats(req, response['status'] as number, start, url);
            }
          }
        },
        (err: HttpErrorResponse) => {
          // log if there is an error
          if (this.shouldLogErrorResponse()) {
            this.logJsonResponse(
              err,
              'error',
              req,
              err.status,
              start,
              url,
              LogLevel.ERROR
            );
          }
          throw err;
        }
      )
    );
  }
  private shouldLogResponse(
    pathname: string,
    logLevel: LogLevel = LogLevel.DEBUG
  ): boolean {
    return (
      this.loggingApiService.isLoggingEnabledFor(logLevel) &&
      !this.containsPath(
        LoggingHttpInterceptor.doNotLogRequestPaths,
        pathname
      ) &&
      !this.containsPath(this.logExclusionPaths, pathname) &&
      this.containsPath(this.logResponsePaths, pathname)
    );
  }

  private shouldLogRequest(
    pathname: string,
    logLevel: LogLevel = LogLevel.DEBUG
  ): boolean {
    return (
      this.loggingApiService.isLoggingEnabledFor(logLevel) &&
      !this.containsPath(
        LoggingHttpInterceptor.doNotLogRequestPaths,
        pathname
      ) &&
      !this.containsPath(this.logExclusionPaths, pathname) &&
      this.containsPath(this.logRequestPaths, pathname)
    );
  }

  private shouldLogStats(
    pathname: string,
    logLevel: LogLevel = LogLevel.DEBUG
  ): boolean {
    return (
      this.loggingApiService.isLoggingEnabledFor(logLevel) &&
      !this.containsPath(this.logExclusionPaths, pathname) &&
      this.containsPath(this.logStatsPaths, pathname)
    );
  }

  private shouldLogErrorResponse(logLevel = LogLevel.ERROR): boolean {
    return this.loggingApiService.isLoggingEnabledFor(logLevel);
  }

  private containsPath(paths: string[], pathname: string): boolean {
    return _.some(paths, p => {
      if (p[0] === '$' && p.length > 1) {
        // compare regex
        return pathname.match(new RegExp(p.slice(1)));
      } else {
        // compare string literal
        return pathname.startsWith(p) || p === '*';
      }
    });
  }

  private logStats(
    req: HttpRequest<any>,
    responseCode: number,
    start: Date,
    url: URL,
    responseCorrelationId?: string
  ) {
    const end = new Date();
    let correlationId;
    if (req && req.headers && req.headers.has('x-correlation-id')) {
      correlationId = req.headers.get('x-correlation-id');
    }
    const elapsedTime = end.getTime() - start.getTime();
    const requestPath = req.url;
    const transactionType = url.pathname;
    this.loggingApiService.logHttpStats(
      LoggingConstants.ActivityNames.ApiGatewayCall,
      transactionType,
      responseCode,
      requestPath,
      req.method,
      elapsedTime,
      responseCorrelationId || correlationId
    );
  }

  private logJsonRequest(
    body: any,
    action: string,
    req: HttpRequest<any>,
    url: URL,
    logLevel: LogLevel = LogLevel.DEBUG
  ) {
    let correlationId;
    if (req.headers.has('x-correlation-id')) {
      correlationId = req.headers.get('x-correlation-id');
    }
    const requestPath = req.url;
    const transactionType = url.pathname;
    this.loggingApiService.logHttpRequestBody(
      body,
      action,
      LoggingConstants.ActivityNames.ApiGatewayCall,
      transactionType,
      requestPath,
      req.method,
      logLevel,
      correlationId
    );
  }

  private logJsonResponse(
    body: any,
    action: string,
    req: HttpRequest<any>,
    responseCode: number,
    start: Date,
    url: URL,
    logLevel: LogLevel = LogLevel.DEBUG
  ) {
    const end = new Date();
    let correlationId;
    if (req.headers.has('x-correlation-id')) {
      correlationId = req.headers.get('x-correlation-id');
    }
    const elapsedTime = end.getTime() - start.getTime();
    const requestPath = req.url;
    const transactionType = url.pathname;
    this.loggingApiService.logHttpResponseBody(
      body,
      action,
      LoggingConstants.ActivityNames.ApiGatewayCall,
      transactionType,
      requestPath,
      req.method,
      responseCode,
      elapsedTime,
      logLevel,
      correlationId
    );
  }
}
